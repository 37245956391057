@import "../../styles/variables.scss";

.homepage-intro--wrapper {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  width: calc(100% - 20px);

  @include desktop {
    flex-direction: row;
    margin: 60px auto;
    width: 100%;
    gap: 20px;
    align-items: stretch;
  }
}

.homepage-intro--content {
  padding: 24px;
  margin-bottom: 24px;
  border-radius: 7px;
  background: #FFFBF3;
  color: #004F31;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.25);

  @include desktop {
    width: 356.66px;
    margin-bottom: 0;
    box-sizing: border-box;
  }

  .homepageIntro--heading {
    margin-top: 0;
  }

  .homepageIntro--heading p {
    font-size: 32px !important;
    line-height: 40px !important;
    margin: 0 0 24px 0 !important;
  }

  .rich-text p {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 12px 0;

    @include desktop {
      font-size: 20px;
      line-height: 28px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.homepage-intro--image {
  max-height: 300px;
  overflow: hidden;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include desktop {
    max-height: 350px;
    width: 733.33px;
    box-sizing: border-box;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.homepage-intro--video {
  max-height: 300px;
  overflow: hidden;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include desktop {
    max-height: 413px;
    width: 733.33px;
    box-sizing: border-box;
  }
}